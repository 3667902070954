import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  save(event) {
    event.preventDefault()
    this.element.submit()
  }

  saveAndAddAnother(event) {
    event.preventDefault()
    this.element.action = this.element.action + '?add_another=1'
    this.element.submit()
  }
}
