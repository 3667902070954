import { Controller } from '@hotwired/stimulus'
import Cleave from 'cleave.js'

export default class extends Controller {
  connect() {
    new Cleave(this.element, {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y']
    })
  }
}
