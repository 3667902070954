import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggleButton']
  static values = {
    id: Number,
    display: Boolean
  }

  toogleSubdocuments() {
    if (this.displayValue) {
      this.subdocuments.forEach(el => {
        el.classList.remove('d-table-row')
        el.classList.add('d-none')
      })
      this.displayValue = false
      this.toggleButtonTarget.classList.remove('fa-chevron-down')
      this.toggleButtonTarget.classList.add('fa-chevron-right')
    } else {
      this.subdocuments.forEach(el => {
        el.classList.remove('d-none')
        el.classList.add('d-table-row')
      })
      this.displayValue = true
      this.toggleButtonTarget.classList.remove('fa-chevron-right')
      this.toggleButtonTarget.classList.add('fa-chevron-down')
    }
  }

  get subdocuments() {
    return document.querySelectorAll(`[data-parent-document="${this.idValue}"]`)
  }
}
