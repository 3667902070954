import { application } from "./application"

import DocumentController from './document_controller'
import DocumentFormController from './document_form_controller'
import InputDateController from './input_date_controller'
import ManageSubdocumentsController from './manage_subdocuments_controller'
import Select2Controller from './select2_controller'

application.register('document', DocumentController)
application.register('document-form', DocumentFormController)
application.register('input-date', InputDateController)
application.register('manage-subdocuments', ManageSubdocumentsController)
application.register('select2', Select2Controller)
