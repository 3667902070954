import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['btnAddAll', 'btnAdd']
  static values = {
    confirmationMessage: String
  }

  addAll(event) {
    event.preventDefault()

    if (confirm(this.confirmationMessageValue)) {
      this.btnAddTargets.forEach(btnAddSubdocument => btnAddSubdocument.click())
      this.btnAddAllTarget.style.display = 'none'
    }
  }
}
