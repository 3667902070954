'use strict';

// Listens to multiple events
function addMultiEventListener(el, e, fn) {
  var events = e.split(' ');
  for (var i = 0; i < events.length; i++) {
    el.addEventListener(events[i], fn, false);
  }
}

class InputFile {
  constructor(options = {}) {
    const standardOptions = {
      buttonText: 'Choose files',
      hint: 'or drag and drop files here',
      message: 'files chosen'
    }
    this.options = Object.assign(standardOptions, options);
  }

  createAll() {
    // Get all the file input fields
    var fields = document.querySelectorAll('input[type="file"]');
    for (var i = 0; i < fields.length; i++) {
      this.createField(fields[i]);
    }
  }

  createField(field) {
    const options = this.options;
    // Create drop area
    const dropArea = document.createElement('div');
    dropArea.className = 'inf__drop-area';
    field.parentNode.insertBefore(dropArea, field);
    dropArea.appendChild(field);

    // Create button
    const btn = document.createElement('span');
    btn.className = 'inf__btn';
    btn.innerText = options.buttonText;
    dropArea.insertBefore(btn, field);

    // Create hint element
    const hint = document.createElement('span');
    hint.className = 'inf__hint';
    hint.innerText = options.hint;
    dropArea.insertBefore(hint, field);

    // Highlight drag area
    addMultiEventListener(field, 'dragenter click focus', () => {
      dropArea.classList.add('is-active');
    });

    // Back to normal state
    addMultiEventListener(field, 'dragleave drop blur', () => {
      dropArea.classList.remove('is-active');
    });

    // Update inner text
    field.addEventListener('change', () => {
      const filesCount = field.files.length;
      if (filesCount === 1) {
        hint.innerText = field.value.split('\\').pop();
      } else {
        hint.innerText = filesCount + ' ' + options.message;
      }
    });
  };
}

export default InputFile;