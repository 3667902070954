import { Controller } from '@hotwired/stimulus';

import 'select2';
import 'select2/dist/js/i18n/pt-BR';

export default class extends Controller {
  static targets = ['field'];
  static values = { placeholder: String, options: Object };

  connect() {
    const customOptions = this.optionsValue;
    const options = Object.assign({
      placeholder: this.placeholderValue,
      theme: 'bootstrap4',
      language: 'pt-BR'
    }, customOptions);

    jQuery(this.fieldTarget).select2(options);
  }

  clear() {
    jQuery(this.fieldTarget).val(null).trigger('change');
  }
}
